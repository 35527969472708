import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import activitiesEN from "./locales/en/activities.json";
import bookingsEN from "./locales/en/bookings.json";
import profilesEN from "./locales/en/profile.json";
import commonEN from "./locales/en/common.json";
import activitiesAR from "./locales/ar/activities.json";
import bookingsAR from "./locales/ar/bookings.json";
import profilesAR from "./locales/ar/profile.json";
import commonAR from "./locales/ar/common.json";

const resources = {
  en: {
    activities: activitiesEN,
    bookings: bookingsEN,
    profiles: profilesEN,
    common: commonEN,
  },
  ar: {
    activities: activitiesAR,
    bookings: bookingsAR,
    profiles: profilesAR,
    common: commonAR,
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: "en",
    lng: "en", // if you want to use a common language by default
    ns: ["activities", "bookings", "profiles", "common"], // defining namespaces
    defaultNS: "common",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true, // Make sure to handle suspense in your components
    },
  });

export default i18n;
