import activity from "../assets/images/activity-gray-icon.svg";
import booking from "../assets/images/booking-gray-icon.svg";
import profile from "../assets/images/profile-gray-icon.svg";
import home from "../assets/images/home-icon.svg";

// import qrCode from "../assets/images/qr-code-gray-icon.svg";
// import promoCode from "../assets/images/promo-code.svg";

interface SidebarMenuItem {
  label: string;
  key: string;
  icon: string;
}

export const SIDEBAR_MENU: SidebarMenuItem[] = [
  {
    label: "Home",
    key: "home",
    icon: home,
  },
  {
    label: "Activities",
    key: "activities",
    icon: activity,
  },
  {
    label: "Bookings",
    key: "bookings",
    icon: booking,
  },
  {
    label: "Profile",
    key: "profile",
    icon: profile,
  },
  // {
  //   label: "QR Code",
  //   key: "qr-code",
  //   icon: qrCode,
  // },
  // {
  //   label: "Promo Code",
  //   key: "promo-code",
  //   icon: qrCode,
  // },
];
