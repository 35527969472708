import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { RouteConfig } from "../types/route";
import RootRedirect from "./RootRedirect ";
import { SIDEBAR_MENU } from "../constants/sidebar";
import PreLoadPagesSpin from "../components/common/PreLoadPagesSpin/PreLoadPagesSpin";
import { getDataFromLocalStorage } from "../utils/helperFunctions";
import { LOGGEDIN_USER } from "../constants/constant";
import ViewHostProfileContainer from "../containers/ViewHostProfileContainer/ViewHostProfileContainer";
const AuthRoute = lazy(() => import("../components/AuthRoute/AuthRoute"));
const MainLayout = lazy(() => import("../layouts/MainLayout/MainLayout"));
const Login = lazy(() => import("../containers/LoginContainer/LoginContainer"));
const Activities = lazy(
  () => import("../containers/ActivitiesContainer/ActivitiesContainer")
);
const GenericFallback = lazy(
  () => import("../components/common/GenericFallBack/GenericFallBack")
);
const BookingContainer = lazy(
  () => import("../containers/BookingContainer/BookingContainer")
);
const ProfileContainer = lazy(
  () => import("../containers/ProfileContainer/ProfileContainer")
);

const HomeContainer = lazy(() => import("../containers/HomeContainer/HomeContainer"));

// const QRCodeContainer = lazy(
//   () => import("../containers/QRCodeContainer/QRCodeContainer")
// );
// const PromoCodeContainer = lazy(
//   () => import("../containers/PromoCodeContainer/PromoCodeContainer")
// );

export const FULL_ROUTES_PATH = {
  HOME:{
    index:  `/${SIDEBAR_MENU[0].key}`,
  },
  ACTIVITIES: {
    index: `/${SIDEBAR_MENU[1].key}`,
  },
  BOOKING: {
    index: `/${SIDEBAR_MENU[2].key}`,
  },
  PROFILE: {
    index: `/${SIDEBAR_MENU[3].key}`,
  },
  // QR_CODE: {
  //   index: `/${SIDEBAR_MENU[3].key}`,
  // },
  // PROMO_CODE: {
  //   index: `/${SIDEBAR_MENU[4].key}`,
  // },
  SIGN_IN: {
    index: "/login",
  },
  PASSWORD: {
    ResetPassword: "/reset-password",
  },
  ERROR: {
    500: "/internal-error",
    404: "/page-not-found",
    403: "/unexpected-error",
  },
};

interface RenderRoutesProps {
  routes: RouteConfig[];
}

const localUserData = getDataFromLocalStorage(LOGGEDIN_USER);
const isViewHost = localUserData?.role === "view-host";

export const RenderRoutes: React.FC<RenderRoutesProps> = ({ routes }) => {
  return (
    <Routes>
      {routes.map((route) => {
        const Component = route.component;
        const Layout = route.layout === "main" ? MainLayout : React.Fragment;
        return route.auth ? (
          <Route
            key={route.key}
            path={route.path}
            element={
              <Layout>
                <AuthRoute>
                  <Suspense fallback={<PreLoadPagesSpin />}>
                    {Component}
                  </Suspense>
                </AuthRoute>
              </Layout>
            }
          />
        ) : (
          <Route key={route.key} path={route.path} element={Component} />
        );
      })}
      {/* Fallback route */}
      <Route path="*" element={<GenericFallback />} />
    </Routes>
  );
};

const ROUTES: RouteConfig[] = [
  {
    path: "/",
    key: "root_redirect",
    component: <RootRedirect />,
    layout: "none",
    exact: true,
  },
  {
    path: FULL_ROUTES_PATH.ACTIVITIES.index,
    key: "activities",
    auth: true,
    component: <Activities />,
    layout: "main",
    exact: true,
  },
  {
    path: FULL_ROUTES_PATH.BOOKING.index,
    key: "booking",
    auth: true,
    component: <BookingContainer />,
    layout: "main",
    exact: true,
  },
  {
    path: FULL_ROUTES_PATH.PROFILE.index,
    key: "profile",
    auth: true,
    component: isViewHost ? <ViewHostProfileContainer /> : <ProfileContainer />,
    layout: "main",
    exact: true,
  },

  {
    path: FULL_ROUTES_PATH.HOME.index,
    key: "home",
    auth: true,
    component: <HomeContainer />,
    layout: "main",
    exact: true,
  },

  // {
  //   path: FULL_ROUTES_PATH.QR_CODE.index,
  //   key: "qr-code",
  //   auth: true,
  //   component: <QRCodeContainer />,
  //   layout: "main",
  //   exact: true,
  // },
  // {
  //   path: FULL_ROUTES_PATH.PROMO_CODE.index,
  //   key: "promo-code",
  //   auth: true,
  //   component: <PromoCodeContainer />,
  //   layout: "main",
  //   exact: true,
  // },
  {
    path: FULL_ROUTES_PATH.SIGN_IN.index,
    key: "sign_in",
    component: <Login />,
    layout: "none",
    exact: true,
  },
  {
    path: FULL_ROUTES_PATH.PASSWORD.ResetPassword,
    key: "reset_password",
    component: <Login forgetPassword />,
    layout: "none",
    exact: true,
  },
];

export const ROUTES_PATH = FULL_ROUTES_PATH;

export default ROUTES;
