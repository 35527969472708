import axios, { AxiosResponse, AxiosError } from "axios";
import { toast } from "../components/common/Toast";
import { getDataFromLocalStorage } from "../utils/helperFunctions";
import { LOGGEDIN_USER } from "../constants/constant";
import i18n from "../i18n";

interface ErrorResponseData {
  data: {
    message: string;
  };
}


const getLanguage = () => i18n.language || "en";


// Common Axios Instance Config

const axiosConfig = {
  // baseURL: "https://dopa.dev.geeksjo.com/api/", // dev
  // baseURL: "http://localhost:8000/api/", // local
  baseURL: "https://app-stg.dopasolutions.com/api/", // stg
  // baseURL: "https://app.dopasolutions.com/api/", // prod
};                                                         

// Create Default Axios Instance
const instance = axios.create(axiosConfig);

// Create Default Axios Instance for actions without Interceptors
const instanceWithoutInterceptors = axios.create(axiosConfig);

// set Common header for without authorization issue....
instanceWithoutInterceptors.interceptors.request.use((config) => {
  config.headers["Accept-Language"] = getLanguage();
  // config.headers["lang"] = getLanguage();
  return config;
});

// Set ID-token as an authorization header for all request when its exists
instance.interceptors.request.use((config) => {
  // Get user info
  const userToken = getDataFromLocalStorage(LOGGEDIN_USER)?.token;  
  config.headers["Accept-Language"] = i18n.language || "en"; // Set language for content localization
  // If userInfo
  if (userToken) {
    config.headers["Authorization"] = `Bearer ${userToken}`; // fetch idToken
  }
  config.headers["Accept-Language"] = getLanguage();
  // config.headers["lang"] = getLanguage();

  return config;
});

instance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response) {
      if (error.response.status === 422) {
        // This will transform your error response to make it look like a successful response
        // for ease of handling in the subsequent .then() block.
        return Promise.resolve(error.response);
      }

      if (error.response.status === 401) {
        window.location.href = "/login";
        return;
      }

      const data = error.response as ErrorResponseData;
      if (data.data.message) {
        toast(data.data.message, "error");
      }
    }
    return Promise.reject(error);
  }
);

// Export Axios with Auth Headers
export default instance;

// Export Axios Without instance
export { instanceWithoutInterceptors as axiosWI };
