import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { UserState } from '../../types/auth';
import { getDataFromLocalStorage, setDataToLocalStorage } from '../../utils/helperFunctions';
import { LOGGEDIN_USER } from '../../constants/constant';




// Define the initial state using that type
let initialState: UserState = {
  id: -1,
  email: "",
  host_id:0,
  name: "",
  first_name: "",
  last_name: "",
  phone: "",
  image: "",
  role:"",
  user_pref: {
    currency:""
  },
  code: 0,
  message:"",
  token: "",
  isUserLoggedIn:false
}

//
let savedUserData = getDataFromLocalStorage(LOGGEDIN_USER);

if (savedUserData) {
  initialState = savedUserData;
}

export const userSlice = createSlice({
  name: 'userInfo',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserState>) => {
      state.id = action.payload.id;
      state.email = action.payload.email;
      state.name = action.payload.name;
      state.first_name = action.payload.first_name;
      state.last_name = action.payload.last_name;
      state.phone = action.payload.phone;
      state.image = action.payload.image;
      state.user_pref = action.payload.user_pref;
      state.code = action.payload.code;
      state.message = action.payload.message;
      state.token = action.payload.token;
      state.role = action.payload.role;
      state.host_id = action.payload.host_id;
      state.isUserLoggedIn = true;
      setDataToLocalStorage(LOGGEDIN_USER, {...action.payload,isUserLoggedIn : true});
    },
    clearUser: state => {
      localStorage.removeItem(LOGGEDIN_USER)
      return initialState;  // Reset the state on logout
    },
  }
})

export const { setUser, clearUser } = userSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectUser = (state: RootState) => state.userInfo

export default userSlice.reducer
