import { Rule } from "antd/lib/form";

export function setDataToLocalStorage(name: string, data: any): void {
  localStorage.setItem(name, JSON.stringify(data));
}

export function getDataFromLocalStorage<T = any>(name: string): T | null {
  const rawData = localStorage.getItem(name);
  if (rawData) {
    return JSON.parse(rawData) as T;
  }
  return null;
}

export function toCamelCase(str: string): string {
  const words = str.split(" ");

  const camelCaseWords = words.map((word, index) => {
    if (index === 0) {
      return word.toLowerCase();
    }
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });

  return camelCaseWords.join("");
}

export const validatePositiveInteger = (t: any): Rule => ({
  validator(_, value) {
    if (value !== undefined && value !== null) {
      const parsedValue = parseInt(value, 10);
      if (
        isNaN(parsedValue) ||
        parsedValue <= 0 ||
        !Number.isInteger(parsedValue)
      ) {
        return Promise.reject(t("common:mustBePositiveInteger"));
      }
    }
    return Promise.resolve();
  },
});

export function formatDateForDayLable(dateString: string) {
  if (dateString) {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      weekday: "long",
      month: "short",
      day: "numeric",
    }).format(date);
  }
  return null;
}

// Function to generate time slots for the day

export const generateTimeSlots = (date: Date, localizer: any): Date[] => {
  const slots: Date[] = [];
  let currentTime = localizer.startOf(date, "day");

  while (localizer.lte(currentTime, localizer.endOf(date, "day"))) {
    slots.push(currentTime);
    currentTime = localizer.add(currentTime, 1, "hour");
  }

  return slots;
};
