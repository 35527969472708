import React, { FC } from "react";
import { UpdatePassword } from "../../types/auth";
import { Button, Card, Col, Form, Input, Row } from "antd";

interface ViewHostProfileProps {
  onChangePassword: (data: UpdatePassword) => void;
}

interface FormValues {
  old_password: string;
  password: string;
}

const ViewHostProfile: FC<ViewHostProfileProps> = ({ onChangePassword }) => {
  const onFinish = (values: FormValues) => {
    onChangePassword(values)
  };
  return (
    <div className="flex-center h-100">
      <Card
        title="Update Password"
        bordered={false}
        style={{ width: "fit-content", borderRadius:16 }}
      >
        <Form
          layout="vertical"
          onFinish={onFinish}
          style={{ maxWidth: 445 }}
          name="basic"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          autoComplete="off"
        >
          <Row>
            <Col span={24}>
              <Form.Item
                label="Old Password"
                name="old_password"
                rules={[
                  {
                    required: true,
                    message: "Old password is required",
                  },
                ]}
              >
                <Input.Password type="password" className="br-8" size="large" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="New Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "New password is required!",
                  },
                ]}
              >
                <Input.Password type="password" className="br-8" size="large" />
              </Form.Item>
            </Col>
            <Col>
              <Button
                type="primary"
                className="add-user-modal-btn"
                htmlType="submit"
              >
                Update
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default ViewHostProfile;
