import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface MetricsState {
  metrics: any[];
  isFetching: boolean;
}
// Define the initial state using that type
let initialState: MetricsState = {
  metrics:[],
  isFetching: true
};

export const metricsSlice = createSlice({
  name: "metricsState",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setMetrics: (state, action: PayloadAction<any>) => {
      state.metrics = action.payload;
    },
    setIsFetching: (state, action: PayloadAction<boolean>) => {
      state.isFetching = action.payload;
    }
  }
});

export const {
  setMetrics,
  setIsFetching
} = metricsSlice.actions;

export const selectMetrics = (state: RootState) => state.metrics;

export default metricsSlice.reducer;
