import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import produce from "immer";
import { CommonState,Column, Filter, Sorting } from "../../types/common";

let initialState: CommonState = {
  start: 0,
  size: 10,
  filter: [],
  globalFilter: "",
  sorting: [],
  columns: [],
  dateId:'',
};

export const activityDateSlice = createSlice({
  name: "activityDateState",
  initialState,
  reducers: {
    setStart: (state, action: PayloadAction<number>) => {
      state.start = action.payload;
    },
    setSize: (state, action: PayloadAction<number>) => {
      state.size = action.payload;
    },
    setFilter: (state, action: PayloadAction<Filter[]>) => {
      state.filter = action.payload;
    },
    setSorting: (state, action: PayloadAction<Sorting[]>) => {
      state.sorting = action.payload;
    },
    setDateId: (state, action: PayloadAction<number | string>) => {
      state.dateId = action.payload;
    },
    setColumns: produce((state, action: PayloadAction<Column[]>) => {
      state.columns = action.payload;
    }),
    clearActivityDate: (state) => {
      return initialState;
    },
  },
});

export const {
  setStart,
  setSize,
  setFilter,
  setSorting,
  setColumns,
  setDateId,
} = activityDateSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectActivityDate = (state: RootState) => state.activityDate;

export default activityDateSlice.reducer;
