import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Column, Filter, Sorting} from "../../types/common";
import produce from "immer";
import { BookingDetails, BookingInitialStae, CaledarEventData, CalendarAddionalData } from "../../types/bookings";
import { DropDownType } from "../../types/activity";
import moment, { Moment } from "moment";
import { useEffect } from "react";


let initialState: BookingInitialStae = {
  start: 0,
  size: 10,
  filter: [],
  globalFilter: "",
  sorting: [],
  columns: [],
  guestColumns:[],
  bookingId:"",
  guestId:"",
  hostActivities:[],
  paymentMethos:[],
  viewBookingDetails:null,
  bookingCalendarData:[],
  selectedDateForDay: "",
  bookingCalendarAdditionalData:null
};

export const bookingSlice = createSlice({
  name: "bookingState",
  initialState,
  reducers: {
    setStart: (state, action: PayloadAction<number>) => {
      state.start = action.payload;
    },
    setSize: (state, action: PayloadAction<number>) => {
      state.size = action.payload;
    },
    setFilter: (state, action: PayloadAction<Filter[]>) => {
      state.filter = action.payload;
    },
    setGuestId: (state, action: PayloadAction<string | number>) => {
      state.guestId = action.payload;
    },
    setBookingCalendarAdditionalData: (state, action: PayloadAction<CalendarAddionalData | null>) => {
      state.bookingCalendarAdditionalData = action.payload;
    },
    setSelectedDateForDay: (state, action: PayloadAction<string | null>) => {
      state.selectedDateForDay = action.payload;
    },
    setBookingId: (state, action: PayloadAction<string | number>) => {
      state.bookingId = action.payload;
    },
    setSorting: (state, action: PayloadAction<Sorting[]>) => {
      state.sorting = action.payload;
    },
    setHostActivities: (state, action: PayloadAction<DropDownType[]>) => {
      state.hostActivities = action.payload;
    },
    setBookingCalendarData: (state, action: PayloadAction<CaledarEventData[]>) => {
      state.bookingCalendarData = action.payload;
    },
    setPaymentMethos: (state, action: PayloadAction<DropDownType[]>) => {
      state.paymentMethos = action.payload;
    },
    setViewBookingDetailsInfo: (state, action: PayloadAction<BookingDetails | null>) => {
      state.viewBookingDetails = action.payload;
    },
    setColumns: produce((state, action: PayloadAction<Column[]>) => {
      state.columns = action.payload;
    }),
    setGuestColumns: produce((state, action: PayloadAction<Column[]>) => {
      state.guestColumns = action.payload;
    }),
    clearBookings: (state) => {
      return initialState;
    },
  },
});

export const {
  setStart,
  setSize,
  setFilter,
  setSorting,
  setColumns,
  clearBookings,
  setHostActivities,
  setPaymentMethos,
  setViewBookingDetailsInfo,
  setGuestColumns,
  setGuestId,
  setBookingId,
  setBookingCalendarData,
  setSelectedDateForDay,
  setBookingCalendarAdditionalData
} = bookingSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectBooking = (state: RootState) => state.booking;

export default bookingSlice.reducer;
