import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { MenuType } from '../../types/common';


let initialState: MenuType = {
  selectedMenu:"activities"
}

export const menuSlice = createSlice({
  name: 'menuInfo',
  initialState,
  reducers: {
    setMenu: (state, action: PayloadAction<string>) => {
      state.selectedMenu=action.payload
    },
    resetMenu: state => {
      return initialState;  
    },
  }
})

export const { setMenu, resetMenu } = menuSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectMenu = (state: RootState) => state.menu

export default menuSlice.reducer
