import React from 'react'
import ViewHostProfile from '../../components/ViewHostProfile/ViewHostProfile'
import { updatePassword } from '../../services/authServices'
import { UpdatePassword } from '../../types/auth'
import { toast } from '../../components/common/Toast'

const ViewHostProfileContainer = () => {

    const onChangePassword = (data: UpdatePassword)=>{
        updatePassword(data).then((res)=>{
            toast(res.data.message,"success")
        })
    }

  return (
    <ViewHostProfile onChangePassword={onChangePassword} />
  )
}

export default ViewHostProfileContainer
