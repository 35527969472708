import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FULL_ROUTES_PATH } from "./routes";

const RootRedirect: React.FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(FULL_ROUTES_PATH.HOME.index);
  }, [navigate]);

  return null; // This component doesn't render anything
};

export default RootRedirect;