import { AxiosResponse } from "axios";
import { ForgetPassword, LoginCredentials, UpdatePassword } from "../types/auth";
import axios from "./api";
import { DestroyRowResponse, DestroyRowResponseError } from "../types/common";

export const login = async (data:LoginCredentials) =>{
  return await axios.post("auth/host-dashboard/login", data)
};


export const logout = async () =>{
  return await axios.post("auth/host-dashboard/logout")
};

export const resetPassword = async (data:ForgetPassword):Promise<AxiosResponse<DestroyRowResponse>>  =>{
  return await axios.post("auth/host-dashboard/forgot-password", data,{
    headers: {
      'device-id': '1' 
    }
  });
};



export const updatePassword = async (data:UpdatePassword):Promise<AxiosResponse<DestroyRowResponseError>>  =>{
  return await axios.post("auth/host-dashboard/update-password", data,{
    headers: {
      'device-id': '2' 
    }
  });
};



