import React from 'react'
import { Spin, Space } from 'antd';
// Component Style
import "./PreLoadPagesSpin.scss";

const PreLoadPagesSpin:React.FC = () => {
  return (
    <div data-testid="pre-loading-page" className={`PreLoadPagesSpin`}>
      <Space size="middle" align="center">
        <Spin size="large" />
      </Space>
    </div>
  );
};

export default PreLoadPagesSpin;
