import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Column, Filter, Sorting } from "../../types/common";
import produce from "immer";
import { ShowProfileData, ShowUserData } from "../../types/profile";
import { DropDownType } from "../../types/activity";

interface ProfileState {
  user_start: number;
  user_size: number;
  user_filter: Filter[];
  user_globalFilter: any;
  user_sorting: Sorting[];
  user_columns: Column[];
  host_start: number;
  host_size: number;
  host_filter: Filter[];
  host_globalFilter: any;
  host_sorting: Sorting[];
  host_columns: Column[];
  hostRole: DropDownType[];
  hostProfileData: ShowProfileData | null;
  viewHostUser: ShowUserData | null
}
// Define the initial state using that type
let initialState: ProfileState = {
  user_start: 0,
  user_size: 10,
  user_filter: [],
  user_globalFilter: "",
  user_sorting: [],
  user_columns: [],
  host_start: 0,
  host_size: 10,
  host_filter: [],
  host_globalFilter: "",
  host_sorting: [],
  host_columns: [],
  hostRole: [],
  hostProfileData: null,
  viewHostUser: null
};

export const profileSlice = createSlice({
  name: "profileState",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setUserStart: (state, action: PayloadAction<number>) => {
      state.user_start = action.payload;
    },
    setUserSize: (state, action: PayloadAction<number>) => {
      state.user_size = action.payload;
    },
    setUserFilter: (state, action: PayloadAction<Filter[]>) => {
      state.user_filter = action.payload;
    },
    setUserSorting: (state, action: PayloadAction<Sorting[]>) => {
      state.user_sorting = action.payload;
    },
    setUserColumns: produce((state, action: PayloadAction<Column[]>) => {
      state.user_columns = action.payload;
    }),
    setHostRole: produce((state, action: PayloadAction<DropDownType[]>) => {
      state.hostRole = action.payload;
    }),
    setHostStart: (state, action: PayloadAction<number>) => {
      state.host_start = action.payload;
    },
    setHostSize: (state, action: PayloadAction<number>) => {
      state.host_size = action.payload;
    },
    setHostFilter: (state, action: PayloadAction<Filter[]>) => {
      state.host_filter = action.payload;
    },
    setHostSorting: (state, action: PayloadAction<Sorting[]>) => {
      state.host_sorting = action.payload;
    },
    setHostProfileData: (state, action: PayloadAction<ShowProfileData>) => {
      state.hostProfileData = action.payload;
    },
    setHostUserData: (state, action: PayloadAction<ShowUserData | null>) => {
      state.viewHostUser = action.payload;
    },
    setHostColumns: produce((state, action: PayloadAction<Column[]>) => {
      state.host_columns = action.payload;
    }),
    clearProfile: (state) => {
      return initialState;
    },
  },
});

export const {
  setUserColumns,
  setUserFilter,
  setUserSize,
  setUserSorting,
  setUserStart,
  setHostColumns,
  setHostFilter,
  setHostSize,
  setHostSorting,
  setHostStart,
  clearProfile,
  setHostProfileData,
  setHostRole,
  setHostUserData
} = profileSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectProfile = (state: RootState) => state.profile;

export default profileSlice.reducer;
