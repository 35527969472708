export const LOGGEDIN_USER: string = "loggedin_user";

export type HeaderKeys =
  | "activities"
  | "booking"
  | "profile"
  | "qr-code"
  | "promo-code";
export type roleKeys = "view-host" | "host";

export const HEADER_HEADING: Record<HeaderKeys, string> = {
  activities: "myActivities",
  booking: "myBookings",
  profile: "hostProfile",
  "qr-code": "QR Code Scanner",
  "promo-code": "Promo Code",
};

export const userRoleType: Record<roleKeys, string> = {
  "view-host": "View Host",
  host: "Host",
};

export const LOCALE = 'locale'