import { configureStore } from '@reduxjs/toolkit'
import userReducer from './slices/userSlice'
import activityReducer from './slices/activitiesSlice';
import menuSlice from './slices/menuSlice';
import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import profileSlice from './slices/profileSlice';
import bookingSlice from './slices/bookingSlice';
import activitySectionSlice from './slices/activitySectionSlice';
import activityPackageSlice from './slices/activityPackageSlice';
import activityDateSlice from './slices/activityDateSlice';
import metricsSlice from './slices/metricsSlice';
import statsSlice from './slices/statsSlice';


const store = configureStore({
  reducer: {
    userInfo: userReducer,
    activity: activityReducer,
    menu: menuSlice,
    profile: profileSlice,
    booking: bookingSlice,
    activitySection: activitySectionSlice,
    activityPackage: activityPackageSlice,
    activityDate: activityDateSlice,
    metrics: metricsSlice,
    stats: statsSlice,
  }
})

export default store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;