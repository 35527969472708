import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface StatsState {
  stats: any[];
  isFetching: boolean;
}
// Define the initial state using that type
let initialState: StatsState = {
  stats:[],
  isFetching: true
};

export const statsSlice = createSlice({
  name: "statsState",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setStats: (state, action: PayloadAction<any>) => {
      state.stats = action.payload;
    },
    setIsFetching: (state, action: PayloadAction<boolean>) => {
      state.isFetching = action.payload;
    }
  }
});

export const {
  setStats,
  setIsFetching
} = statsSlice.actions;

export const selectStats = (state: RootState) => state.stats;

export default statsSlice.reducer;
