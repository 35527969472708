import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import produce from "immer";
import { CommonState,Column, Filter, Sorting } from "../../types/common";
import { ViewActivitySection } from "../../types/activitySection";

let initialState: CommonState = {
  start: 0,
  size: 10,
  filter: [],
  globalFilter: "",
  sorting: [],
  columns: [],
  sectionId:'',
  itemId:'',
  sectionView:{activity_title:'', number_of_items:0, section_id:''},
  sectionItemColumns:[]
};

export const activitySectionSlice = createSlice({
  name: "activitySectionState",
  initialState,
  reducers: {
    setStart: (state, action: PayloadAction<number>) => {
      state.start = action.payload;
    },
    setSize: (state, action: PayloadAction<number>) => {
      state.size = action.payload;
    },
    setFilter: (state, action: PayloadAction<Filter[]>) => {
      state.filter = action.payload;
    },
    setSorting: (state, action: PayloadAction<Sorting[]>) => {
      state.sorting = action.payload;
    },
    setSectionId: (state, action: PayloadAction<number | string>) => {
      state.sectionId = action.payload;
    },
    setItemId: (state, action: PayloadAction<number | string>) => {
      state.itemId = action.payload;
    },
    setSectionVew: (state, action: PayloadAction<ViewActivitySection>) => {
      state.sectionView = action.payload;
    },
    setColumns: produce((state, action: PayloadAction<Column[]>) => {
      state.columns = action.payload;
    }),
    setSectionItemsColumn: produce((state, action: PayloadAction<Column[]>) => {
      state.sectionItemColumns = action.payload;
    }),
    clearActivitySection: (state) => {
      return initialState;
    },
  },
});

export const {
  setStart,
  setSize,
  setFilter,
  setSorting,
  setColumns,
  setSectionId,
  setSectionItemsColumn,
  setSectionVew,
  setItemId
} = activitySectionSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectActivitySection = (state: RootState) => state.activitySection;

export default activitySectionSlice.reducer;
