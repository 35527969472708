import { notification } from "antd";

type NotificationType = 'success' | 'info' | 'warning' | 'error';

export const toast = (
  message: string = "",
  type: NotificationType,
  description: string = "",
  placement: "topLeft" | "topRight" | "bottomLeft" | "bottomRight" = "topRight"
): void => {
  notification[type]({
    message,
    description,
    placement,
  });
};
