import React from 'react';

const GenericFallback: React.FC = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <h1>Page not found</h1>
    </div>
  );
}

export default GenericFallback;
