import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import produce from "immer";
import { Column, Filter, Sorting } from "../../types/common";
import {
  ActivityDatesData,
  ActivityDetailsData,
  ActivityInitialState,
  ActivityLocationData,
  ActivityTicketData,
  AddActivityLocationType,
  CronValueApiResponse,
  DropDownType,
  ImageData,
  Section,
} from "../../types/activity";
import { ActivityDateData } from "../../types/activityDate";

let initialState: ActivityInitialState = {
  start: 0,
  size: 10,
  filter: [],
  globalFilter: "",
  sorting: [],
  columns: [],
  cities: [],
  subCategories: [],
  policePlans: [],
  activityCategories: [],
  activityFeatures: [],
  activitySections: [],
  activityId: "",
  cronValues: null,
  location: {
    latitude: 31.9639,
    longitude: 35.9106,
    location_title: "",
    map_link: "",
  },
  viewActivityDetails: null,
  viewActivityLocation: null,
  viewActivityTickets: null,
  viewActivitySectionItems: null,
  viewActivityDates: null,
  viewActivityImages: null,
  activityAdultParticipation:[],
};

export const activitySlice = createSlice({
  name: "activityState",
  initialState,
  reducers: {
    setStart: (state, action: PayloadAction<number>) => {
      state.start = action.payload;
    },
    setSize: (state, action: PayloadAction<number>) => {
      state.size = action.payload;
    },
    setFilter: (state, action: PayloadAction<Filter[]>) => {
      state.filter = action.payload;
    },
    setSorting: (state, action: PayloadAction<Sorting[]>) => {
      state.sorting = action.payload;
    },
    setActivityFeatures: (state, action: PayloadAction<DropDownType[]>) => {
      state.activityFeatures = action.payload;
    },
    setActivityAdultParticipation: (state, action: PayloadAction<DropDownType[]>) => {
      state.activityAdultParticipation = action.payload;
    },
    setActivityId: (state, action: PayloadAction<string>) => {
      state.activityId = action.payload;
    },
    setCronValues: (
      state,
      action: PayloadAction<CronValueApiResponse["data"]>
    ) => {
      state.cronValues = action.payload;
    },
    setLocationValues: (
      state,
      action: PayloadAction<AddActivityLocationType>
    ) => {
      state.location = action.payload;
    },
    setViewActivityDetailsData: (
      state,
      action: PayloadAction<ActivityDetailsData | null>
    ) => {
      state.viewActivityDetails = action.payload;
    },
    setViewActivityLocationData: (
      state,
      action: PayloadAction<ActivityLocationData | null>
    ) => {
      state.viewActivityLocation = action.payload;
    },
    setViewActivityTicketsData: (
      state,
      action: PayloadAction<ActivityTicketData[] | null>
    ) => {
      state.viewActivityTickets = action.payload;
    },
    setViewActivitySectionItems: (
      state,
      action: PayloadAction<Section[] | null>
    ) => {
      state.viewActivitySectionItems = action.payload;
    },
    setViewActivityDates: (
      state,
      action: PayloadAction<ActivityDatesData[] | null>
    ) => {
      state.viewActivityDates = action.payload;
    },
    setViewActivityImages: (
      state,
      action: PayloadAction<ImageData | null>
    ) => {
      state.viewActivityImages = action.payload;
    },
    setColumns: produce((state:ActivityInitialState, action: PayloadAction<Column[]>) => {
      state.columns = action.payload;
    }),
    setCities: produce((state:ActivityInitialState, action: PayloadAction<DropDownType[]>) => {
      state.cities = action.payload;
    }),
    setSubCategories: produce(
      (state:ActivityInitialState, action: PayloadAction<DropDownType[]>) => {
        state.subCategories = action.payload;
      }
    ),
    setPolicePlans: produce((state:ActivityInitialState, action: PayloadAction<DropDownType[]>) => {
      state.policePlans = action.payload;
    }),
    setActivityCategories: produce(
      (state:ActivityInitialState, action: PayloadAction<DropDownType[]>) => {
        state.activityCategories = action.payload;
      }
    ),
    setActivitySections: produce(
      (state:ActivityInitialState, action: PayloadAction<DropDownType[]>) => {
        state.activitySections = action.payload;
      }
    ),
    clearActivities: (state) => {
      return initialState;
    },
  },
});

export const {
  setStart,
  setSize,
  setFilter,
  setSorting,
  setColumns,
  setCities,
  clearActivities,
  setSubCategories,
  setPolicePlans,
  setActivityCategories,
  setActivitySections,
  setActivityId,
  setCronValues,
  setLocationValues,
  setActivityFeatures,
  setViewActivityDetailsData,
  setViewActivityLocationData,
  setViewActivityTicketsData,
  setViewActivitySectionItems,
  setViewActivityDates,
  setViewActivityImages,
  setActivityAdultParticipation,
} = activitySlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectActivity = (state: RootState) => state.activity;

export default activitySlice.reducer;
